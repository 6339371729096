export default {
  setCredenciales: (state, payload) => {
    const credenciales = JSON.parse(payload);
    state.isAuthorized = credenciales.isAuthorized;
    state.userInfo = credenciales.userInfo;
    state.token = credenciales.token;
    var info = JSON.parse(localStorage.getItem("infoClient"));
    state.clientInfo = info;
  },
  snackbar: (state, payload) => {
    state.showSnackbar = payload.snackbar;
    state.message = payload.text;
    state.colorSnackBar = payload.color;
  },
  setNavbarToggle: (state, payload) => {
    state.navbarToggle = payload;
  },
  setNavDrawerToggle: (state, payload) => {
    state.navDrawerToggle = payload;
  },
  setIsAuthorized: (state, payload) => {
    state.isAuthorized = payload;
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setToken: (state, payload) => {
    state.token = payload;
  },
  setTittle: (state, payload) => {
    state.tittle = payload;
  },
  setclientInfo: (state, payload) => {
    state.clientInfo = payload;
  },
};
