<template>
  <v-app style="background: #141428">
    <navbar-global v-if="isAuthorized"></navbar-global>
    <v-main>
      <login v-if="!isAuthorized"></login>
      <router-view v-if="isAuthorized" />
    </v-main>
    <v-snackbar
      v-model="showSnackbar"
      outlined
      :color="colorSnackBar"
      timeout="10000"
      top
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="colorSnackBar"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Login from "@/views/Login";
import NavbarGlobal from "@/components/NavbarGlobal";
export default {
  name: "App",

  components: {
    Login,
    NavbarGlobal,
  },

  data: () => ({
    options: {
      enableHighAccuracy: false,
      timeout: 240000,
      maximumAge: 0,
    },
    crd: {},
  }),

  computed: {
    ...mapState([
      "isAuthorized",
      "version",
      "message",
      "colorSnackBar",
      "url",
      "userInfo",
      "token",
    ]),
    showSnackbar: {
      get: function () {
        return this.$store.state.showSnackbar;
      },
      set: function (val) {
        this.$store.state.showSnackbar = val;
      },
    },
  },

  watch: {
    crd(val) {
      this.updateLastLoc(val);
    },
  },

  async mounted() {
    this.$root.$on("GetLocationUser", () => {
      return this.GetLocationUser();
    });
    const credenciales = await localStorage.getItem("credenciales");
    if (credenciales != null) {
      await this.$store.commit("setCredenciales", credenciales);
    }
    if (!this.isAuthorized && this.$route.path != "/login") {
      this.$router.push("/login");
    }
    if (this.isAuthorized) {
      this.GetLocationUser();
      if (this.$route.path == "/" || this.$route.path == "/login") {
        this.$router.push("/clientdetail");
      }
    }
  },

  // async updated() {
  //   const credenciales = await localStorage.getItem("credenciales");
  //   if (credenciales != null) {
  //     await this.$store.commit("setCredenciales", credenciales);
  //     this.$router.push("/clientdetail");
  //   }
  //   if (!this.isAuthorized && this.$route.path != "/login") {
  //     this.$router.push("/login");
  //   }
  // },

  methods: {
    GetLocationUser() {
      if (this.isAuthorized) {
        navigator.geolocation.getCurrentPosition(
          this.success,
          this.error,
          this.options
        );
        setTimeout(() => this.GetLocationUser(), 300000);
      }
    },
    success(pos) {
      const crd = pos.coords;
      this.crd = crd;
    },
    error(err) {
      console.log(`ERROR(${err.code}): ${err.message}`);
    },
    async updateLastLoc(crd) {
      var obj = [
        {
          lat: crd.latitude,
          lon: crd.longitude,
          speed: crd.speed,
          bearing: crd.heading,
        },
      ];
      const response = await fetch(
        this.url + "/users/gps-history/updateLoc/" + this.userInfo.IdUsuario,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify(obj),
        }
      );
      const decodedResponse = await response.json();
      if (response.status == 200) {
        console.log(decodedResponse);
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
  },
};
</script>
