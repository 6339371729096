export default {
  url: "https://jomarwebws.jomarweb.com",
  //url: "http://192.168.6.192:3000",
  userInfo: {},
  token: "",
  version: "v25.2",
  isAuthorized: false,
  showSnackbar: false,
  message: "",
  colorSnackBar: "",
  navbarToggle: 0,
  navDrawerToggle: false,
  tittle: "",
  navDrawerOpciones: [
    { text: "Crear Pedido", icon: "mdi-cart", url: "/ClientDetail" },
    { text: "Perfil", icon: "mdi-account", url: "/perfil" },
    { text: "Visitas Clientes", icon: "mdi-run", url: "/VisitaClientes" },
    {
      text: "Revisa Ubicación Cliente",
      icon: "mdi-earth",
      url: "/ubicacion_cliente",
    },
    {
      text: "Catalogo Electronico",
      icon: "mdi-book",
      url: "/CatalogoElectronico",
    },
  ],
  clientInfo: {},
};
