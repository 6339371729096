import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Login from "@/views/Login";
import ClientDetail from "@/views/ClientDetail";
import Pedido from "@/views/Pedido";
import VisitaClientes from "@/views/VisitaClientes";
import CatalogoElectronico from "@/views/CatalogoElectronico";
import PantallaPerfil from "@/views/PantallaPerfil";
import PantallaUbicacionCliente from "@/views/PantallaUbicacionCliente";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "LoginSession",
    component: Login,
  },
  {
    path: "/clientdetail",
    name: "ClientDetail",
    component: ClientDetail,
  },
  {
    path: "/pedidocliente",
    name: "PedidoCliente",
    component: Pedido,
  },
  {
    path: "/VisitaClientes",
    name: "VisitaClientes",
    component: VisitaClientes,
  },
  {
    path: "/CatalogoElectronico",
    name: "CatalogoElectronico",
    component: CatalogoElectronico,
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: PantallaPerfil,
  },
  {
    path: "/ubicacion_cliente",
    name: "Ubicacion de Cliente",
    component: PantallaUbicacionCliente,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
