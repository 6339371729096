<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-autocomplete
            label="Selecciona Grupo"
            v-model="autocomGrupo"
            :items="listaAutoGrupo"
            item-text="Grupo"
            item-value="IdGrupo"
            @change="traerListaCategoria"
            dense
            dark
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            label="Selecciona Categoria"
            v-model="autocomCategoria"
            :items="listaAutoCategoria"
            item-text="Categoria"
            item-value="IdCategoria"
            @change="traerListaCatalogo"
            dense
            dark
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            ref="autoCatlog"
            label="Selecciona Catalogo"
            v-model="autocomCatalogo"
            :items="listaAutoCatalogo"
            item-text="descripcion"
            item-value="URL"
            @change="openURL"
            dense
            dark
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headersListaClientes"
        :items="listaClientesData"
        :mobile-breakpoint="0"
        dark
        disable-pagination
        hide-default-footer
        class="elevation-1 blue-grey darken-4"
      ></v-data-table>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    autocomGrupo: 0,
    listaAutoGrupo: [],
    autocomCategoria: "",
    listaAutoCategoria: [],
    autocomCatalogo: "",
    listaAutoCatalogo: [],
    headersListaClientes: [
      {
        text: "Catalogo",
        value: "Numeracion",
      },
      {
        text: "Usuario",
        value: "usuario",
      },
      {
        text: "Password",
        value: "password",
      },
    ],
    listaClientesData: [],
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
    tittle: {
      get: function () {
        return this.$store.state.tittle;
      },
      set: function (val) {
        this.$store.commit("setTittle", val);
      },
    },
  },
  async mounted() {
    this.tittle = "Catalogo Electronico";
    this.llenarTabla();
    this.traerListaGrupo();
  },
  methods: {
    async llenarTabla() {
      try {
        const response = await fetch(this.url + "/products/cuentas", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.listaClientesData = value.Respuesta;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerListaGrupo() {
      try {
        const response = await fetch(this.url + "/products/grupo", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.listaAutoGrupo = value.Respuesta;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerListaCategoria(IdGrupo) {
      console.log(IdGrupo);
      try {
        const response = await fetch(
          this.url + "/products/categorias/" + IdGrupo,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.listaAutoCategoria = value.Respuesta;
          this.limpiarAutocom();
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerListaCatalogo(IdCategoria) {
      try {
        const response = await fetch(
          this.url + "/products/catalogos/" + IdCategoria,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.listaAutoCatalogo = value.Respuesta;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openURL(URL) {
      window.open(URL, "_blank");
    },
    limpiarAutocom() {
      this.autocomCategoria = null;
      this.autocomCatalogo = null;
    },
  },
};
</script>
<style></style>
