<template lang="">
  <v-container>
    <v-img contain src="@/assets/logo_new.png" max-height="100"></v-img>

    <v-row>
      <v-col
        ><v-text-field
          dark
          disabled
          v-model="nombre"
          label="Nombre"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        ><v-text-field
          dark
          disabled
          v-model="usuario"
          label="Usuario"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        ><v-text-field
          dark
          disabled
          v-model="telefono1"
          label="Teléfono"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        ><v-text-field
          dark
          disabled
          v-model="telefono2"
          label="Teléfono secundario"
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col
        ><v-text-field
          dark
          disabled
          v-model="direccion"
          label="Dirección"
        ></v-text-field
      ></v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PantallaPerfil",
  data: () => ({
    nombre: "",
    usuario: "",
    telefono1: "",
    telefono2: "",
    direccion: "",
  }),
  computed: {
    ...mapState(["url", "token", "userInfo"]),
  },
  mounted() {
    this.nombre = this.userInfo.Nombre;
    this.usuario = this.userInfo.email;
    this.telefono1 = this.userInfo.phone;
    this.telefono2 = this.userInfo.phone_secondary;
    this.direccion = this.userInfo.address;
  },
};
</script>
<style lang=""></style>
