<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-btn fab dark @click="dialog = true" x-small>
            <v-icon dense>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn fab text dark x-small @click="llenarTabla()">
            <v-icon dense>mdi-sync</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
            label="ClaveCliente"
            v-model="guardaClaveCliente"
            outlined
            dense
            dark
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-btn dense dark color="primary" @click="guardarCliente()"
            >Guardar</v-btn
          >
        </v-col>
      </v-row>
      <v-data-table
        :headers="headersClient"
        :items="visitasData"
        :mobile-breakpoint="0"
        dark
        hide-default-footer
        disable-pagination
        class="elevation-1 blue-grey darken-4"
      >
        <template v-slot:[`item.Boton`]="{ item }">
          <v-btn
            block
            :disabled="item.Boton == 0 ? false : true"
            color="primary"
            @click="mandarChecks('CheckIn', item)"
          >
            CheckIn
          </v-btn>
          <v-btn
            block
            :disabled="item.Boton == 1 ? false : true"
            color="primary"
            @click="mandarChecks('CheckOut', item)"
          >
            CheckOut
          </v-btn>
          <v-btn
            block
            :disabled="item.Boton == 0 ? false : true"
            color="primary"
            @click="eliminaVisita(item)"
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      dark
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Lista de Clientes</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Buscar Cliente"
                  v-model="buscaClaveCliente"
                  outlined
                  dense
                  dark
                  clearable
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn dense dark color="primary" @click="buscarCliente()">
                  Buscar Cliente
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn dense dark color="primary" @click="dialog = false">
                  Salir
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headersBuscar"
              :items="clienteFiltradoData"
              :mobile-breakpoint="0"
              disable-pagination
              hide-default-footer
              class="elevation-1 blue-grey darken-4"
              @click:row="handleRowClick"
            >
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog2"
      fullscreen
      hide-overlay
      dark
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ingresa el numero telefonico del cliente</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Telefono del cliente"
                  v-model="telefono"
                  outlined
                  dense
                  dark
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn dense dark color="primary" v-on:click="GuardaTelefono()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
var moment = require("moment");
export default {
  data: () => ({
    dialog: false,
    guardaClaveCliente: "",
    buscaClaveCliente: "",
    Lat: 0,
    Lng: 0,
    headersClient: [
      {
        text: "Clave Cliente",
        value: "ClaveCliente",
      },
      {
        text: "Razon Social",
        value: "RazonSocial",
      },
      {
        text: "Acciones",
        value: "Boton",
      },
    ],
    visitasData: [],
    headersBuscar: [
      {
        text: "Clave Cliente",
        value: "clave",
      },
      {
        text: "Razon Social",
        value: "razonsocial",
      },
      {
        text: "RFC",
        value: "rfc",
      },
    ],
    clienteFiltradoData: [],
    clientSelect: {},
    checked: "",
    telefono: "",
    dialog2: false,
  }),
  computed: {
    ...mapState(["url", "userInfo", "token"]),
    computedDateFormattedMomentjs() {
      return this.fechaDeHoy ? moment(this.fechaDeHoy).format("MMMM/YYYY") : "";
    },
    tittle: {
      get: function () {
        return this.$store.state.tittle;
      },
      set: function (val) {
        this.$store.commit("setTittle", val);
      },
    },
  },
  async mounted() {
    await this.llenarTabla();
    this.getLocation();
    this.tittle = "Visitas del Dia";
  },
  methods: {
    handleRowClick(value) {
      this.guardaClaveCliente = value.clave;
      this.dialog = false;
    },
    async getLocation() {
      await navigator.geolocation.getCurrentPosition(
        this.onSuccess,
        this.onError
      );
    },
    onSuccess(position) {
      const { latitude, longitude } = position.coords;
      //console.log(`Your location: ${latitude},${longitude}`);
      this.Lat = latitude;
      this.Lng = longitude;
    },
    onError() {
      //console.log(`Failed to get your location!`);
    },
    async guardarCliente() {
      try {
        const response = await fetch(this.url + "/clients/GuardaLogCliente", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            IdUsuario: this.userInfo.IdUsuario,
            ClaveCliente: this.guardaClaveCliente,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.llenarTabla();
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async buscarCliente() {
      try {
        const response = await fetch(this.url + "/clients/search", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            term: this.buscaClaveCliente,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.clienteFiltradoData = value;
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async llenarTabla() {
      try {
        var fechaIni =
          moment().format("YYYY") +
          "" +
          moment().format("MM") +
          "" +
          moment().format("DD") +
          " 00:00:00";
        var fechaFin =
          moment().format("YYYY") +
          "" +
          moment().format("MM") +
          "" +
          moment().format("DD") +
          " " +
          "23:59:59";
        const response = await fetch(this.url + "/clients/TraerLogClientes", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            IdUsuario: this.userInfo.IdUsuario,
            FechaIni: fechaIni,
            FechaFin: fechaFin,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.visitasData = value.Respuesta;
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async eliminaVisita(item) {
      try {
        const response = await fetch(this.url + "/clients/EliminalogCliente", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            IdUsuario: this.userInfo.IdUsuario,
            ClaveCliente: item.ClaveCliente,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.llenarTabla();
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async mandarChecks(Check, item) {
      try {
        const response = await fetch(this.url + "/users/BuscaRegistroClteCAT", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            clave: item.ClaveCliente,
            idUsuario: this.userInfo.IdUsuario,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          if (value[0].resultado == 1) {
            this.realizaCheck(Check, item);
          } else {
            this.clientSelect = item;
            this.checked = Check;
            this.dialog2 = true;
          }
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async realizaCheck(Check, item) {
      try {
        await this.getLocation();
        console.log(Check);
        console.log(item);
        let body = JSON.stringify({
          IdUsuario: this.userInfo.IdUsuario,
          check: Check,
          lat: this.Lat,
          lng: this.Lng,
        });
        console.log(body);
        const response = await fetch(
          this.url + "/users/visitLogs2/" + item.ClaveCliente,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: body,
          }
        );
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.llenarTabla();
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GuardaTelefono() {
      try {
        if (this.telefono.length != 10) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "El numero tiene que tener 10 digitos.",
            color: "error",
          });
          return;
        }
        let body = JSON.stringify({
          IdUsuario: this.userInfo.IdUsuario,
          claveCliente: this.clientSelect.ClaveCliente,
          Telefono: this.telefono,
        });
        console.log(body);
        const response = await fetch(
          this.url + "/users/InsertaLogRegistroClteCAT",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: body,
          }
        );
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.dialog2 = false;
          this.telefono = "";
          this.realizaCheck(this.checked, this.clientSelect);
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: value.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style></style>
