import Vue from "vue";
import Vuex from "vuex";
import state from "@/Modules/state";
import mutations from "@/Modules/mutations";
import actions from "@/Modules/actions";

Vue.use(Vuex);

export default new Vuex.Store({
  state: state,
  getters: {},
  mutations: mutations,
  actions: actions,
  modules: {},
});
