<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="3">
          <v-dialog v-model="dialog" fullscreen>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                color="secondary"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </template>

            <v-card color="#141428">
              <v-card-title class="white--text justify-center">
                Lista de Clientes
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-text-field
                    dark
                    filled
                    :value="buscaCliente"
                    label="Buscar Cliente"
                    prepend-icon="mdi-magnify"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-col cols="6" xs="12">
                    <v-btn block dark color="primary" @click="fillTable()">
                      BUSCAR CLIENTE
                    </v-btn>
                  </v-col>
                  <v-col cols="6" xs="12"
                    ><v-btn block dark color="primary" @click="dialog = false">
                      SALIR
                    </v-btn></v-col
                  >
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-container>
                  <v-row>
                    <v-col cols="12"
                      ><v-data-table
                        dark
                        :headers="headers"
                        :items="contenidoTabla"
                        mobile-breakpoint="100"
                        :items-per-page="contenidoTabla.length"
                        class="elevation-1"
                        hide-default-footer
                        @click:row="handleRowClick"
                      ></v-data-table
                    ></v-col> </v-row
                ></v-container>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
            filled
            dark
            dense
            hide-details
            v-model="claveBusca"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn dark block @click="llenarCoordenadas()" color="primary">
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" class="white--text">Tu ubicación actual: </v-col>
        <v-col cols="3">
          <v-btn dark fab text x-small>
            <v-icon @click="getLocation()"> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="9" lg="9" class="white--text">{{
          primeraLinea
        }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="3" lg="3" class="white--text">{{
          segundaLinea1
        }}</v-col>
        <v-col cols="12" xl="9" lg="9" class="white--text">{{
          segundaLinea2
        }}</v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            dark
            :disabled="switchButton"
            @click="guardarCoor()"
            color="primary"
            >GUARDAR</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "PantallaUbicacionCliente",
  data: () => ({
    switchButton: true,
    dialog: false,
    claveBusca: "",
    buscaCliente: "",
    segundaLinea1: "",
    segundaLinea2: "",
    primeraLinea: "",
    headers: [
      { text: "Clave", value: "clave" },
      { text: "Razon Social", value: "razonsocial" },
      { text: "RFC", value: "rfc" },
    ],
    contenidoTabla: [],
  }),
  computed: {
    ...mapState(["url", "token", "userInfo"]),
    tittle: {
      get: function () {
        return this.$store.state.tittle;
      },
      set: function (val) {
        this.$store.commit("setTittle", val);
      },
    },
  },
  methods: {
    async fillTable() {
      const response = await fetch(this.url + "/clients/search", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify({
          term: this.buscaCliente,
        }),
      });
      if (response.status == 200) {
        const tableFill = await response.json();
        console.log(tableFill);
        this.contenidoTabla = tableFill;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async llenarCoordenadas() {
      if (this.claveBusca == "") {
        return;
      }
      this.segundaLinea1 = "Ubicación guardada del cliente: ";
      const response = await fetch(
        this.url + "/clients/id/" + this.claveBusca,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        }
      );
      if (response.status == 200) {
        const infoDeClave = await response.json();
        console.log(infoDeClave);
        this.segundaLinea2 =
          infoDeClave.info[0].Latitud + ", " + infoDeClave.info[0].Longitud;
        this.switchButton = false;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    async guardarCoor() {
      const response = await fetch(this.url + "/clients/edit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify({
          IdCliente: this.claveBusca,
          Latitud: this.latitude,
          Longitud: this.longitude,
        }),
      });
      if (response.status == 200) {
        const tableFill = await response.json();
        console.log(tableFill);
        this.contenidoTabla = tableFill;
      } else {
        const value = await response.json();
        let body = {
          status: response.status,
          mensaje: value.mensaje,
        };
        this.$store.dispatch("responseError", body);
      }
    },
    handleRowClick(value) {
      this.claveBusca = value.clave;
      this.dialog = false;
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);
    },
    onSuccess(position) {
      const { latitude, longitude } = position.coords;
      //console.log(`Your location: ${latitude},${longitude}`);
      this.primeraLinea = latitude + ", " + longitude;
    },
    onError() {
      //console.log(`Failed to get your location!`);
    },
  },
  mounted() {
    this.getLocation();
    this.tittle = "Ubicación Cliente";
  },
};
</script>
<style scoped>
.searchButton {
  width: 80%;
}
</style>
