<template>
  <div>
    <!--Pantalla principal-->
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-btn
            fab
            text
            x-small
            dark
            left
            v-on:click="TrarPendientesPagoClientes()"
          >
            <v-icon> mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row style="margin-top: 0 !important">
        <v-col id="info" cols="12">
          <div
            class="subtitle-2"
            style="color: white; font-weight: 300; font-size: 12px !important"
          >
            Suc. seleccionada: {{ userInfo.Sucursal }}
          </div>
        </v-col>
        <v-col id="info" cols="12">
          <div
            class="subtitle-2"
            style="color: white; font-weight: 300; font-size: 12px !important"
          >
            Clave: {{ clientInfo.clave }}
            {{ "consecutivo" in clientInfo ? clientInfo.consecutivo : "" }}
          </div>
        </v-col>
        <v-col id="info" cols="12">
          <div
            class="subtitle-2"
            style="color: white; font-weight: 300; font-size: 12px !important"
          >
            Nombre: {{ clientInfo.razonsocial }}
          </div>
        </v-col>
        <v-col id="info" cols="12" v-if="promobefore != ''">
          <div
            class="subtitle-2"
            style="color: white; font-weight: 300; font-size: 12px !important"
          >
            Promocion seleccionada: {{ promobefore }}
          </div>
        </v-col>
        <v-col id="info" cols="12" v-if="visiblegrid2">
          <div
            class="subtitle-2"
            style="color: white; font-weight: 300; font-size: 12px !important"
          >
            Credito disponible: {{ limitepedido }}
          </div>
        </v-col>
      </v-row>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-text-field
            v-model="searchProduct"
            label="Buscar producto"
            dark
            hide-details
            solo-inverted
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn block depressed color="primary" v-on:click="SearchProduct()">
            Buscar Producto
          </v-btn>
        </v-col>
      </v-row>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn
            block
            depressed
            color="primary"
            v-on:click="AbrirCatalogoElect()"
          >
            Catalogo Electronico
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="listProducts.length != 0"
        id="rows"
        align="center"
        justify="center"
      >
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-autocomplete
            v-model="productSelec"
            :items="listProducts"
            :item-text="getItemText"
            dense
            dark
            label="Seleccione..."
            filled
            return-object
            hide-details
            @change="(event) => InfoProduct(event)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="product">
        <v-col cols="12">
          <v-card color="rgba(255,255,255,0.2)">
            <v-card-text style="padding: 0">
              <v-container>
                <v-row>
                  <v-col id="colCard" cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Codigo: {{ productSelec.itemno }}
                    </div>
                  </v-col>
                  <v-col id="colCard2" cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Descripción: {{ productSelec.descripcion }}
                    </div>
                  </v-col>
                  <v-col id="colCard2" cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Categoria: {{ productSelec.Categoria }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col id="colCard" cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Precio Sin Iva: $ {{ product.info[0].Precio }}
                    </div>
                  </v-col>
                  <v-col id="colCard2" cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Precio Con Iva: $ {{ product.info[0].PrecioIVA }}
                    </div>
                  </v-col>
                  <v-col id="colCard3" cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Existencia: {{ product.info[0].Existencia }}
                    </div>
                  </v-col>
                </v-row>
                <v-divider id="dividerCard" dark></v-divider>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card color="rgba(255,255,255,0.2)">
            <v-card-text style="padding: 0">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div
                      class="subtitle-2"
                      style="
                        color: white;
                        font-weight: 300;
                        font-size: 12px !important;
                        line-height: normal;
                      "
                    >
                      Agregar al pedido:
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-text-field
            style="padding-top: 0; margin-top: 0"
            v-model="qty"
            dark
            append-icon="mdi-basket"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            depressed
            dense
            color="primary"
            v-on:click="BuscarInventario()"
          >
            Ver inventario en <br />
            todas las sucursales
          </v-btn>
        </v-col>
      </v-row>
      <!--<v-row v-if="product">
        <v-col cols="12">
          <v-btn
            block
            depressed
            dense
            color="primary"
            v-on:click="BuscarInventarioProv()"
            :disabled="desbtnInvProv"
            :dark="desbtnInvProv"
          >
            Existencia Proveedores
          </v-btn>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col cols="6">
          <v-btn block depressed dense color="primary" v-on:click="addToCart()">
            Agregar +
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn block depressed dense color="primary" v-on:click="onCart()">
            Ver Pedido
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <!--Dialog que presenta informacion del cliente al entrar-->
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-title style="justify-content: center">
          <span id="X" class="text-h6" @click="dialog = false">X</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="visiblegrid">
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Datos del cliente
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Tipo de pago:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Pago de contado
                </div>
              </v-col>
            </v-row>
            <v-row v-if="infoLocal.creditoretenido == 1">
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Se cambio a contado ya que el cliente tiene credito retenido.
                </div>
              </v-col>
            </v-row>
            <v-row v-if="compare > infoLocal.limitecredito">
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Se cambio a contado ya que el cliente no cuenta con credito
                  disponible.
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="visiblegrid2">
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Datos del cliente
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Tipo de pago:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Credito
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Dias de credito:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  {{ infoLocal.diascredito }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Limite de credito:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  {{ infoLocal.limitecredito }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Saldo pendiente:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  $ {{ infoLocal.saldo }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Pendiente facturar:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  $ {{ infoLocal.ImportePedidosCliente }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Credito disponible:
                </div>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  $ {{ limitepedido }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  *Si es caso de que sobrepase el límite de crédito del cliente
                  se cambiara en automático el pedido a de contado.*
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialog para mostrar existencias-->
    <v-dialog
      v-model="dialog2"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-title style="justify-content: center">
          <span id="X" class="text-h6" @click="dialog2 = false">X</span>
        </v-card-title>
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersInv"
                  :items="inventario"
                  :items-per-page="inventario.length"
                  hide-default-footer
                  mobile-breakpoint="0"
                  dark
                  style="background-color: #141428"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialog para mostrar existencias-->
    <v-dialog
      v-model="dialog3"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-title style="justify-content: center">
          <span id="X" class="text-h6" @click="onCart()">X</span>
        </v-card-title>
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row
              v-for="(value, index) in cart"
              :key="value.product.info[0].Articulo"
            >
              <v-col cols="12">
                <v-card color="rgba(255,255,255,0.2)">
                  <v-card-text style="padding: 0">
                    <v-container>
                      <v-row>
                        <v-col id="colCard" cols="10">
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            {{ value.product.info[0].Articulo }}
                          </div>
                        </v-col>
                        <v-col id="colCard" cols="2" align="end" justify="end">
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                            @click="removeFromCart(index)"
                          >
                            X
                          </div>
                        </v-col>
                        <v-col id="colCard2" cols="12">
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            {{ value.product.info[0].Descripcion }}
                          </div>
                        </v-col>
                        <v-col id="colCard2" cols="12">
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            Existencia: {{ value.product.info[0].Existencia }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            Precio
                          </div>
                        </v-col>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            Cantidad
                          </div>
                        </v-col>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            Importe
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            $ {{ value.product.info[0].Precio }}
                          </div>
                        </v-col>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                          v-if="value.product.info[0].PromocionId == 0"
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            <div id="sum" @click="addQty(index)">+</div>
                            {{ value.qty }}
                            <div id="res" @click="removeQty(index)">-</div>
                          </div>
                        </v-col>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                          v-else
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            {{ value.qty }}
                          </div>
                        </v-col>
                        <v-col
                          id="colCard"
                          cols="4"
                          align="center"
                          justify="center"
                        >
                          <div
                            class="subtitle-2"
                            style="
                              color: white;
                              font-weight: 300;
                              font-size: 12px !important;
                              line-height: normal;
                            "
                          >
                            $
                            {{
                              (
                                value.product.info[0].Precio * value.qty
                              ).toFixed(2)
                            }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider id="dividerCard" dark></v-divider>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col id="colCard" cols="12" align="center" justify="center">
                <div
                  class="subtitle-2"
                  style="
                    color: white;
                    font-weight: 300;
                    font-size: 12px !important;
                    line-height: normal;
                  "
                >
                  Descuento: $
                  {{ totaldesc }}
                </div>
              </v-col>
              <v-col id="colCard2" cols="12" align="center" justify="center">
                <div
                  class="subtitle-2"
                  style="
                    color: white;
                    font-weight: 300;
                    font-size: 12px !important;
                    line-height: normal;
                  "
                >
                  Total s/IVA: $
                  {{ totalsiva }}
                </div>
              </v-col>
              <v-col id="colCard3" cols="12" align="center" justify="center">
                <div
                  class="subtitle-2"
                  style="
                    color: white;
                    font-weight: 300;
                    font-size: 12px !important;
                    line-height: normal;
                  "
                >
                  Total c/IVA: $
                  {{ totalciva }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col id="colCard" cols="12" align="center" justify="center">
                <v-btn
                  block
                  :disabled="cart.length == 0"
                  depressed
                  dense
                  color="primary"
                  dark
                  v-on:click="ModifyPromos()"
                >
                  Modificar Promoción
                </v-btn>
              </v-col>
              <v-col id="colCard" cols="12" align="center" justify="center">
                <v-btn
                  block
                  :disabled="cart.length == 0"
                  depressed
                  dense
                  color="primary"
                  dark
                  v-on:click="CalcularCarrito()"
                >
                  Recalcular carrito
                </v-btn>
              </v-col>
              <v-col id="colCard" cols="12">
                <v-radio-group v-model="row" row>
                  <v-radio
                    color="primary"
                    dark
                    label="Cliente Acude"
                    value="O"
                  ></v-radio>
                  <v-spacer></v-spacer>
                  <v-radio
                    color="primary"
                    dark
                    label="A Domicio"
                    value="D"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-col id="colCard" cols="12" align="center" justify="center">
              <v-text-field
                v-model="comentarios"
                label="Comentarios"
                dark
                hide-details
                solo-inverted
              ></v-text-field>
            </v-col>
            <v-col id="colCard" cols="12" align="center" justify="center">
              <v-btn
                block
                :disabled="disButtonPed"
                depressed
                dense
                color="primary"
                dark
                v-on:click="revisaPosibleCambioCont()"
              >
                Pedir
              </v-btn>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialog que presenta promociones de articulos-->
    <v-dialog
      v-model="dialog4"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Promociones de producto
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  id="tablaPromo"
                  :headers="headersPromos"
                  :items="promo"
                  :items-per-page="promo.length"
                  hide-default-footer
                  mobile-breakpoint="0"
                  dark
                  style="background-color: #141428"
                  @click:row="handleRowClick"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Dialog para modificar cantidad de promos a vender-->
    <v-dialog
      v-model="dialog5"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Promociones de producto
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  id="tablaPromo"
                  :headers="headersPromosM"
                  :items="ListPromoEd"
                  :items-per-page="ListPromoEd.length"
                  hide-default-footer
                  mobile-breakpoint="0"
                  dark
                  style="background-color: #141428"
                >
                  <template v-slot:[`item.quantity`]="{ item }">
                    <v-text-field
                      v-model="item.totalProm"
                      flat
                      solo-inverted
                      hide-details
                    ></v-text-field>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn color="primary" block @click="dialog5 = false">
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="primary" block @click="CambiarCantidades()">
                  Cambiar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dCambioCont" max-width="400" style="border-radius: 12px">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <sweetalert-icon v-if="visibleAction == 0" icon="info" />
              <sweetalert-icon v-else icon="loading" />
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h2>{{ textDiagSupervisor.Title }}</h2>
            </v-col>
          </v-row>
          <v-row style="text-align: center">
            <v-col cols="12" style="padding: 5px">
              <h3>{{ textDiagSupervisor.Body }}</h3>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="padding: 12px 16px">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-4"
            @click="closeDialogConfirm()"
            v-if="visibleAction == 0"
          >
            No
          </v-btn>
          <v-btn
            color="red"
            dark
            v-if="visibleAction == 0"
            v-on:click="CambioManualContado()"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog6"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="subtitle-2">
                  El articulo seleccionado no tiene existencia, ¿Deseas ver
                  otros articulos equivalentes?
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding: 12px 16px">
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-4"
            @click="BuscaInfoArticulo(productSelec)"
          >
            No
          </v-btn>
          <v-btn color="red" dark v-on:click="BuscaSimilares(productSelec)">
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog7"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Productos Equivalentes
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  id="tablaPromo"
                  :headers="headersArtsSim"
                  :items="ListArtSim"
                  :items-per-page="ListArtSim.length"
                  hide-default-footer
                  mobile-breakpoint="0"
                  dark
                  style="background-color: #141428"
                  @click:row="handleRowClickArtSim"
                >
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn
                  color="primary"
                  block
                  @click="BuscaInfoArticulo(productSelec)"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog8"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="#141428">
        <v-card-title style="justify-content: center">
          <span id="X" class="text-h6" @click="dialog8 = false">X</span>
        </v-card-title>
        <v-card-text style="padding: 0 2px 2px 0">
          <v-container>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <div class="subtitle-2" style="color: white; font-weight: 300">
                  Catalogo Electronico
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedYear"
                  :items="years"
                  label="Año"
                  dense
                  hide-details
                  item-text="YearValue"
                  item-value="YearValue"
                  dark
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedMarca"
                  :items="marcas"
                  label="Marca"
                  item-text="MakeName"
                  item-value="MakeID"
                  dense
                  hide-details
                  dark
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedModelo"
                  :items="modelos"
                  label="Modelo"
                  dense
                  item-text="modelo"
                  item-value="ModelID"
                  hide-details
                  dark
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedMotor"
                  :items="motores"
                  label="Motor"
                  dense
                  item-text="EngineBaseName"
                  item-value="EngineBaseID"
                  hide-details
                  dark
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="selectedProdArt"
                  :items="prodArt"
                  label="Producto/Parte"
                  dense
                  item-text="PartTerminologyName"
                  item-value="PartTerminologyID"
                  hide-details
                  dark
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :disabled="selectedModelo == '' || selectedModelo == null"
                  :dark="selectedModelo == '' || selectedModelo == null"
                  dense
                  large
                  @click="traerProductos()"
                >
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  id="tablaPromo"
                  :headers="headersProductos"
                  :items="listaProductos"
                  :items-per-page="listaProductos.length"
                  hide-default-footer
                  mobile-breakpoint="0"
                  dark
                  style="background-color: #141428"
                  @click:row="handleRowClickProdCat"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-alert
      :value="alerta"
      prominent
      type="success"
      transition="scale-transition"
      elevation="14"
      style="z-index: 300; margin-left: 14px; margin-right: 14px"
    >
      <v-row align="center">
        <v-col class="grow">
          El pedido se ha creado correctamente, el folio de pedido es:
          {{ folioPedido }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="Regresar()">OK</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-footer absolute padless height="25" color="#141428">
      <div
        class="subtitle-2"
        style="
          color: white;
          font-weight: 300;
          font-size: 12px !important;
          padding-left: 12px;
        "
      >
        {{ version }}
      </div>
      <div
        class="subtitle-2"
        style="
          color: white;
          font-weight: 300;
          font-size: 12px !important;
          padding-left: 12px;
        "
      >
        {{ userInfo.Sucursal.trim() }}
      </div>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PedidoCliente",
  data: () => ({
    dialog: false,
    OriginalDaysCredit: 0,
    visiblegrid: false,
    visiblegrid2: false,
    infoLocal: [],
    limitepedido: 0,
    compare: 0,
    promobefore: "",
    searchProduct: "",
    listProducts: [],
    productSelec: null,
    product: false,
    dialog4: false,
    promo: [],
    headersPromos: [
      {
        text: "Id Promocion",
        sortable: false,
        value: "PromocionId",
      },
      {
        text: "Existencia",
        sortable: false,
        value: "Existencia",
      },
      {
        text: "Promociones",
        sortable: false,
        value: "DescripcionPromo",
      },
    ],
    qty: 1,
    cart: [],
    headersInv: [
      {
        text: "Sucursal",
        sortable: false,
        value: "Sucursal",
      },
      {
        text: "Existencia",
        sortable: false,
        value: "Disp",
      },
    ],
    inventario: [],
    dialog2: false,
    dialog3: false,
    disButtonPed: true,
    totaldesc: 0,
    totalsiva: 0,
    totalciva: 0,
    comentarios: "",
    row: "O",
    dialog5: false,
    headersPromosM: [
      {
        text: "Id Promocion",
        sortable: false,
        value: "idPromo",
      },
      {
        text: "Promociones",
        sortable: false,
        value: "descripcion",
      },
      {
        text: "Cant. Promo",
        sortable: false,
        value: "quantity",
      },
    ],
    ListPromoEd: [],
    visibleAction: 0,
    textDiagSupervisor: {
      Title: "Calculando",
      Body: "Por favor, espere...",
    },
    dCambioCont: false,
    alerta: false,
    folioPedido: 0,
    dialog6: false,
    dialog7: false,
    headersArtsSim: [
      {
        text: "Articulo",
        sortable: false,
        value: "Itemno",
      },
      {
        text: "Descripción",
        sortable: false,
        value: "Descripcion",
      },
      {
        text: "Existencia Suc",
        sortable: false,
        value: "ExistenciaSucursal",
      },
      {
        text: "Existencia CEDIS",
        sortable: false,
        value: "ExistenciaMatriz",
      },
    ],
    ListArtSim: [],
    dialog8: false,
    years: [],
    selectedYear: "",
    marcas: [],
    selectedMarca: "",
    modelos: [],
    selectedModelo: "",
    motores: [],
    selectedMotor: "",
    prodArt: [],
    selectedProdArt: "",
    listaProductos: [],
    headersProductos: [
      {
        text: "Articulo",
        sortable: false,
        value: "CodJomar",
      },
      {
        text: "Descripción",
        sortable: false,
        value: "DescripcionJomar",
      },
      {
        text: "Existencia",
        sortable: false,
        value: "Existencia",
      },
      {
        text: "Precio",
        sortable: false,
        value: "Precio",
      },
    ],
    lastItemno: "",
    desbtnInvProv: false,
  }),
  computed: {
    ...mapState(["userInfo", "url", "token", "clientInfo", "version"]),
    tittle: {
      get: function () {
        return this.$store.state.tittle;
      },
      set: function (val) {
        this.$store.commit("setTittle", val);
      },
    },
  },

  watch: {
    selectedYear() {
      this.marcas = [];
      this.selectedMarca = "";
      this.modelos = [];
      this.selectedModelo = "";
      this.motores = [];
      this.selectedMotor = "";
      this.listaProductos = [];
      this.selectedProdArt = "";
      this.TraerMarcas();
    },
    selectedMarca(marca) {
      this.modelos = [];
      this.selectedModelo = "";
      this.motores = [];
      this.selectedMotor = "";
      this.listaProductos = [];
      this.selectedProdArt = "";
      this.TraerModelos(marca);
    },
    selectedModelo(modelo) {
      this.motores = [];
      this.selectedMotor = "";
      this.listaProductos = [];
      this.selectedProdArt = "";
      this.TraerMotores(modelo);
      this.TraerProdArt();
    },
    selectedMotor() {
      this.listaProductos = [];
      this.selectedProdArt = "";
    },
    selectedProdArt() {
      this.listaProductos = [];
    },
  },
  mounted() {
    this.tittle = "Agregar producto al pedido";
    this.TrarPendientesPagoClientes();
    this.TraerListaYears();
  },

  methods: {
    async TrarPendientesPagoClientes() {
      let bodyJSON = "";
      bodyJSON = JSON.stringify({
        Operacion: "consecutivo" in this.clientInfo ? "A" : "E",
        Clave: this.clientInfo.clave.trim(),
        Registros: "consecutivo" in this.clientInfo ? -1 : null,
        Consecutivo:
          "consecutivo" in this.clientInfo ? this.clientInfo.consecutivo : null,
      });
      const response = await fetch(this.url + "/clients/searchAlternos2", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: bodyJSON,
      });
      const decodedResponse = await response.json();
      if (response.status == 200) {
        if (decodedResponse.Respuesta[0].diascredito > 0) {
          this.savelastinfoclient(decodedResponse.Respuesta[0].diascredito);
        }
        let total = 0;
        let infobody = decodedResponse.Respuesta[0];
        total =
          infobody.limitecredito -
          (infobody.saldo + infobody.ImportePedidosCliente);
        let compare = infobody.saldo + infobody.ImportePedidosCliente;
        if (infobody.creditoretenido == 1 || infobody.limitecredito < compare) {
          infobody.diascredito = 0;
        }
        infobody.diascredito == 0
          ? this.SetVisibleGrid(true, false)
          : this.SetVisibleGrid(false, true);
        this.infoLocal = infobody;
        this.dialog = true;
        this.limitepedido = total.toFixed(2);
        this.compare = compare;
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    savelastinfoclient(value) {
      this.OriginalDaysCredit = value;
    },
    SetVisibleGrid(val, val2) {
      this.visiblegrid = val;
      this.visiblegrid2 = val2;
    },
    async SearchProduct() {
      if (this.searchProduct == "") {
        return;
      }
      let bodyJSON = "";
      bodyJSON = JSON.stringify({
        term: this.searchProduct,
        sucursal: this.userInfo.Sucursal,
      });
      const response = await fetch(this.url + "/products/search", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: bodyJSON,
      });
      const decodedResponse = await response.json();
      if (response.status == 200) {
        this.listProducts = decodedResponse;
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    getItemText(item) {
      return `${
        item.Existencia
      } - ${item.itemno.trim()} - ${item.descripcion.trim()}`;
    },
    async InfoProduct(value) {
      if (value.Existencia == 0) {
        console.log(this.productSelec);
        this.dialog6 = true;
      } else {
        this.BuscaInfoArticulo(value);
      }
    },
    handleRowClick(value) {
      if (value.Habilitar == 0) {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Promoción solo valida para CONTADO.",
          color: "error",
        });
        return;
      }
      if (value.PromocionId > 0) {
        let obj2 = [];
        obj2.push(value);
        this.product = { info: obj2 };
        this.qty = value.Piezas;
        this.promobefore = value.PromocionId + " - " + value.DescripcionPromo;
        this.disButtonPed = true;
        this.addToCart();
      } else {
        this.dialog4 = false;
        this.promobefore = "";
      }
    },
    addToCart() {
      let cant = this.qty;
      let carrito = this.cart;
      if (!this.product) {
        return;
      }
      let salir = false;
      carrito.forEach((value) => {
        if (value.product.info[0].Articulo == this.product.info[0].Articulo) {
          salir = true;
          return;
        }
      });
      if (salir) {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "No se puede agregar mas de una vez el mismo articulo...",
          color: "error",
        });
        return;
      }
      if (isNaN(cant)) {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "No es un numero valido",
          color: "error",
        });
        return;
      }
      if (cant < 1) {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Seleciona un numero mayor a 0",
          color: "error",
        });
        return;
      }
      carrito.push({
        qty: cant,
        product: this.product,
      });
      this.$store.commit("snackbar", {
        snackbar: true,
        text: "Se agrego al carrito",
        color: "success",
      });
      this.cart = carrito;
      this.product = false;
      this.promo = [];
      this.listProducts = [];
      this.searchProduct = "";
      this.productSelec = null;
      this.dialog4 = false;
      this.qty = 1;
      this.disButtonPed = true;
      if (this.infoLocal.creditoretenido == 0) {
        if (this.OriginalDaysCredit != 0) {
          this.CambiaCreditoContado();
        }
      }
    },
    CambiaCreditoContado() {
      let total = this.calulateTotalIVAparacalculos();
      let infocliente = this.infoLocal;
      if (total > this.limitepedido && this.visiblegrid2) {
        infocliente.diascredito = 0;
        this.infoLocal = infocliente;
        this.visiblegrid = true;
        this.visiblegrid2 = false;
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Se cambio el credito del cliente a pago de contado ya que se sobrepaso el limite disponible del cliente.",
          color: "error",
        });
      }
      if (total <= this.limitepedido && this.visiblegrid) {
        infocliente.diascredito = this.OriginalDaysCredit;
        this.infoLocal = infocliente;
        this.visiblegrid = false;
        this.visiblegrid2 = true;
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Se regreso a credito el cliente ya que nuevamente se esta respetando el limite de credito disponible.",
          color: "error",
        });
      }
    },
    calulateTotalIVAparacalculos() {
      let cart = this.cart;
      if (cart.length == 0) {
        return 0;
      }
      let total = 0;
      for (let i = 0; i < cart.length; i++) {
        const item = cart[i];
        total += item.qty * item.product.info[0].PrecioIVA;
      }
      return total;
    },
    async BuscarInventario() {
      const response = await fetch(this.url + `/products/inventoryNew`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify({ itemNo: this.productSelec.itemno }),
      });
      const decodedResponse = await response.json();
      if (response.status == 200) {
        if (decodedResponse.length != 0) {
          this.inventario = decodedResponse;
          this.dialog2 = true;
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "No se encontro informacion",
            color: "error",
          });
        }
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    onCart() {
      this.dialog3 = !this.dialog3;
      this.CalculaTotales();
      if (this.clientInfo.creditoretenido == 0) {
        if (this.OriginalDaysCredit != 0) {
          this.CambiaCreditoContado();
        }
      }
    },
    removeFromCart(i) {
      let cart = this.cart;
      cart.splice(i, 1);
      this.cart = cart;
      this.disButtonPed = true;
      this.CalculaTotales();
    },
    addQty(index) {
      let cart = this.cart;
      let qty = cart[index].qty;
      qty++;
      cart[index].qty = qty;
      this.cart = cart;
      this.disButtonPed = true;
      this.CalculaTotales();
    },
    removeQty(index) {
      let cart = this.cart;
      let qty = cart[index].qty;
      qty--;
      if (qty == 0) {
        this.removeFromCart(index);
        return;
      }
      cart[index].qty = qty;
      this.cart = cart;
      this.disButtonPed = true;
      this.CalculaTotales();
    },
    calulateTotal() {
      let cart = this.cart;
      if (cart.length == 0) {
        return 0;
      }
      let total = 0;
      for (let i = 0; i < cart.length; i++) {
        const item = cart[i];
        total += item.qty * item.product.info[0].Precio;
      }
      return total.toFixed(2);
    },

    calulateTotalIVA() {
      let cart = this.cart;
      if (cart.length == 0) {
        return 0;
      }
      let total = 0;
      for (let i = 0; i < cart.length; i++) {
        const item = cart[i];
        total += item.qty * item.product.info[0].PrecioIVA;
      }
      return total.toFixed(2);
    },

    calculaDescuento() {
      let cart = this.cart;
      if (cart.length == 0) {
        return 0;
      }
      let total = 0;
      for (let i = 0; i < cart.length; i++) {
        const item = cart[i];
        total +=
          (item.product.info[0].PrecioFact - item.product.info[0].Precio) *
          item.qty;
      }
      return total.toFixed(2);
    },
    ModifyPromos() {
      let carrito = this.cart;
      let obj = [];
      carrito.forEach((value) => {
        if (value.product.info[0].PromocionId > 0) {
          let totalpromo = value.qty / value.product.info[0].Piezas;
          obj.push({
            idPromo: value.product.info[0].PromocionId,
            descripcion: value.product.info[0].DescripcionPromo,
            totalProm: totalpromo,
          });
        }
      });
      /*Aqui en esta parte se hace algo parecido a un distinct de SQL, es en base al idPromo*/
      if (obj.length > 0) {
        let uniqueObjArray = [
          ...new Map(obj.map((item) => [item["idPromo"], item])).values(),
        ];
        (this.ListPromoEd = uniqueObjArray), (this.dialog5 = true);
      }
    },

    CambiarCantidades() {
      let cart = this.cart;
      this.ListPromoEd.forEach((element) => {
        cart.forEach((element2) => {
          if (element.idPromo == element2.product.info[0].PromocionId) {
            element2.qty = element2.product.info[0].Piezas * element.totalProm;
          }
        });
      });
      this.cart = cart;
      this.dialog5 = false;
      this.disButtonPed = true;
      this.CalculaTotales();
    },

    CalculaTotales() {
      this.totalsiva = this.calulateTotal();
      this.totalciva = this.calulateTotalIVA();
      this.totaldesc = this.calculaDescuento();
    },
    async CambioManualContado() {
      let infocliente = this.infoLocal;
      infocliente.diascredito = 0;
      await this.cambiarManual(infocliente);

      this.sendOrder();
    },
    cambiarManual(info) {
      this.infoLocal = info;
      this.OriginalDaysCredit = 0;
      this.visiblegrid = true;
      this.visiblegrid2 = false;
      this.dCambioCont = false;
    },
    closeDialogConfirm() {
      this.dCambioCont = false;
      this.sendOrder();
    },
    revisaPosibleCambioCont() {
      let infocliente = this.infoLocal;
      if (infocliente.diascredito != 0) {
        this.dCambioCont = true;
        this.visibleAction = 0;
        this.textDiagSupervisor.Title = "Cambio";
        this.textDiagSupervisor.Body =
          "El pedido se puede pasar a contado. ¿Deseas cambiarlo?";
      } else {
        this.sendOrder();
      }
    },
    async CalcularCarrito() {
      try {
        this.disButtonPed = true;
        this.dCambioCont = true;
        this.visibleAction = 1;
        this.textDiagSupervisor.Title = "Calculando";
        this.textDiagSupervisor.Body = "Por favor, espere...";
        let cart = this.cart;
        let sucursal = this.userInfo.Sucursal.trim();
        const xmlDoc = document.implementation.createDocument(null, "Carrito");
        var elements = xmlDoc.getElementsByTagName("Carrito");
        // const Encabezado = xmlDoc.createElement("Carrito");
        const rootElement = xmlDoc.createElement("Header");
        rootElement.setAttribute("Sucursal", sucursal);
        rootElement.setAttribute("Cliente", this.clientInfo.clave);
        elements[0].appendChild(rootElement);
        this.cart.forEach((element) => {
          const rootElement2 = xmlDoc.createElement("Articulo");
          rootElement2.setAttribute("ItemNo", element.product.info[0].Articulo);
          rootElement2.setAttribute("CantPedida", element.qty);
          rootElement2.setAttribute(
            "PrecioUnit",
            element.product.info[0].Precio
          );
          rootElement2.setAttribute(
            "PrecioFact",
            element.product.info[0].PrecioFact
          );
          rootElement2.setAttribute(
            "PromocionId",
            element.product.info[0].PromocionId
          );
          rootElement2.setAttribute(
            "ComponenteId",
            element.product.info[0].ComponenteId
          );
          elements[0].appendChild(rootElement2);
        });

        const xmlSerializer = new XMLSerializer();
        const xmlOutput = xmlSerializer.serializeToString(xmlDoc);

        const response = await fetch(
          this.url + `/products/product/calculatePromoFinal`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({ articulos: xmlOutput }),
          }
        );
        const decodedResponse = await response.json();
        if (response.status == 200) {
          if (decodedResponse.length != 0) {
            let artbody = decodedResponse.Respuesta;

            for (let i = 0; i < cart.length; i++) {
              let Delete = true;
              for (let j = 0; j < artbody.length; j++) {
                if (cart[i].product.info[0].Articulo == artbody[j].Itemno) {
                  Delete = false;
                  cart[i].product.info[0].Precio = artbody[j].Precio;
                  cart[i].product.info[0].PrecioIVA = artbody[j].PrecioIVA;
                  cart[i].product.info[0].PromocionId = artbody[j].PromocionId;
                  cart[i].product.info[0].DescripcionPromo =
                    artbody[j].Descripcion;
                  cart[i].product.info[0].Piezas = artbody[j].Piezas;
                  cart[i].product.info[0].ComponenteId =
                    artbody[j].ComponenteId;
                }
              }
              if (Delete) {
                cart.splice(i, 1);
                i--;
              }
            }

            for (let i = 0; i < artbody.length; i++) {
              let ADD = true;
              for (let j = 0; j < cart.length; j++) {
                if (artbody[i].Itemno == cart[j].product.info[0].Articulo) {
                  ADD = false;
                }
              }
              if (ADD) {
                cart.push({
                  product: {
                    info: [
                      {
                        Articulo: artbody[i].Itemno,
                        ComponenteId: artbody[i].ComponenteId,
                        Precio: artbody[i].Precio,
                        PrecioIVA: artbody[i].PrecioIVA,
                        Descripcion: artbody[i].DescripcionArt,
                        PromocionId: artbody[i].PromocionId,
                        DescripcionPromo: " ",
                        Existencia: artbody[i].Existencia,
                        PrecioFact: artbody[i].Precio,
                        CostoProm: artbody[i].Precio,
                        Almacen: "",
                        Descuento: 0,
                      },
                    ],
                  },
                  qty: artbody[i].Cantpedida,
                });
              }
            }
            this.cart = cart;
            this.disButtonPed = false;
            this.dCambioCont = false;
            this.CalculaTotales();
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontro informacion",
              color: "error",
            });
          }
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }

        if (this.infoLocal.creditoretenido == 0) {
          if (this.OriginalDaysCredit != 0) {
            this.CambiaCreditoContado();
          }
        }
      } catch (err) {
        this.disButtonPed = true;
        this.dCambioCont = false;
      }
    },
    async sendOrder() {
      try {
        this.disButtonPed = true;
        this.dCambioCont = true;
        this.visibleAction = 1;
        this.textDiagSupervisor.Title = "Generando Pedido";
        this.textDiagSupervisor.Body = "Por favor, espere...";
        if (this.cart.length == 0) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "No se puede generar el pedido sin articulos...",
            color: "error",
          });
          this.dCambioCont = false;
          return;
        }
        let Exit = false;
        let cart2 = this.cart;
        for (let i = 0; i < cart2.length; i++) {
          const element = cart2[i];
          if (element.product.info[0].Existencia == 0) {
            Exit = true;
            cart2.splice(i, 1);
            i--;
            continue;
          }
          if (element.qty > element.product.info[0].Existencia) {
            Exit = true;
            cart2[i].qty = element.product.info[0].Existencia;
            continue;
          }
        }
        this.cart = cart2;
        //let fechaEntrega;
        if (Exit) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Se ajusto el carrito en base a existencias. Revisa los cambios y vuelve a recalcular carrito...",
            color: "error",
          });
          this.dCambioCont = false;
          return;
        }
        let order = {
          client: this.clientInfo.clave,
          total: this.calulateTotal(),
          totalIVA: this.calulateTotalIVA(),
          typePayment: "",
          cart: this.cart,
          //fechaEntrega: fechaEntrega,
          comments: this.comentarios,
          seller: this.userInfo,
        };

        const xmlDoc = document.implementation.createDocument(null, "Pedido");
        var elements = xmlDoc.getElementsByTagName("Pedido");
        // const Encabezado = xmlDoc.createElement("Pedido");
        const rootElement = xmlDoc.createElement("PedidoEncabezado");
        rootElement.setAttribute("FolioCotizacion", 0);
        rootElement.setAttribute("Sucursal", order.seller.Sucursal);
        rootElement.setAttribute("CusNo", order.client);
        rootElement.setAttribute("CusNoEntregarA", order.client);
        rootElement.setAttribute("CondicionPago", this.infoLocal.diascredito);
        rootElement.setAttribute("VendedorCve", order.seller.ClaveVend);
        rootElement.setAttribute("TarifaImpuesto", 0);
        rootElement.setAttribute("PorcentajeImpuesto", 0);
        rootElement.setAttribute("Comentario", order.comments);
        rootElement.setAttribute("IdUsuario", order.seller.IdUsuario);
        rootElement.setAttribute("DescuentoPedido", 0);
        rootElement.setAttribute(
          "ClienteFacturarAConsec",
          "consecutivo" in this.clientInfo ? this.clientInfo.consecutivo : 0
        ); //Aqui agregar lo del consecutivo
        rootElement.setAttribute("ClienteEntregarAConsec", 0);
        rootElement.setAttribute("ClienteCobrarA", order.client);
        rootElement.setAttribute("ClienteCobrarAConsec", 0);
        rootElement.setAttribute("ClienteOtro", "");
        rootElement.setAttribute("ClienteOtroConsec", 0);
        rootElement.setAttribute("Descuento", 0);
        rootElement.setAttribute("Supervisor", 0);
        rootElement.setAttribute("UsuarioSupervisor", "");
        rootElement.setAttribute("SupervisorCasco", "");
        rootElement.setAttribute("Origen", "A");
        rootElement.setAttribute("IdEntregaCliente", this.row);
        elements[0].appendChild(rootElement);

        const rootElement2 = xmlDoc.createElement("PedidoDetalle");
        var rengArt = 1;
        order.cart.forEach((element) => {
          const rootElement3 = xmlDoc.createElement("Articulo");
          rootElement3.setAttribute("Renglon", rengArt);
          rootElement3.setAttribute("ItemNo", element.product.info[0].Articulo);
          rootElement3.setAttribute("CantidadPedido", element.qty);
          rootElement3.setAttribute("CantidadSurtido", 0);
          rootElement3.setAttribute(
            "PrecioUnitario",
            element.product.info[0].Precio
          );
          rootElement3.setAttribute(
            "Descuento",
            element.product.info[0].Descuento
          );
          rootElement3.setAttribute("TipoItem", 1);
          rootElement3.setAttribute("Almacen", element.product.info[0].Almacen);
          rootElement3.setAttribute(
            "CondicionPago",
            this.infoLocal.diascredito
          );
          rootElement3.setAttribute("Sucursal", order.seller.Sucursal);
          rootElement3.setAttribute(
            "CostoUnitario",
            element.product.info[0].CostoProm
          );
          rootElement3.setAttribute("Impuesto", 0);
          rootElement3.setAttribute(
            "PrecioFactura",
            element.product.info[0].PrecioFact
          );
          rootElement3.setAttribute(
            "PromocionId",
            element.product.info[0].PromocionId
          );
          rootElement3.setAttribute("Kit", 0);
          rengArt++;
          rootElement2.appendChild(rootElement3);
        });
        elements[0].appendChild(rootElement2);

        const xmlSerializer = new XMLSerializer();
        const xmlOutput = xmlSerializer.serializeToString(xmlDoc);

        const response = await fetch(this.url + `/products/order`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({ xmlOrden: xmlOutput }),
        });
        const decodedResponse = await response.json();
        if (response.status == 200) {
          this.disButtonPed = true;
          this.dCambioCont = false;
          if (decodedResponse.resultado[0].FolioPedido != 0) {
            this.alerta = true;
            this.folioPedido = decodedResponse.resultado[0].FolioPedido;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "El pedido no se a podido generar, favor de avisar a sistemas...",
              color: "error",
            });
          }
        } else {
          if (response.status == 401) {
            this.dCambioCont = false;
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.dCambioCont = false;
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          }
        }
      } catch (err) {
        this.dCambioCont = false;
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Ocurrio un error, intentelo mas tarde.",
          color: "error",
        });
      }
    },
    Regresar() {
      this.$router.push("/ClientDetail");
    },
    createXML() {
      let sucursal = this.userInfo.Sucursal.trim();
      const xmlDoc = document.implementation.createDocument(null, "Carrito");
      var elements = xmlDoc.getElementsByTagName("Carrito");
      // const Encabezado = xmlDoc.createElement("Carrito");
      const rootElement = xmlDoc.createElement("Header");
      rootElement.setAttribute("Sucursal", sucursal);
      elements[0].appendChild(rootElement);
      this.cart.forEach((element) => {
        const rootElement2 = xmlDoc.createElement("Articulo");
        rootElement2.setAttribute("ItemNo", element.product.info[0].Articulo);
        rootElement2.setAttribute("CantPedida", element.qty);
        rootElement2.setAttribute("PrecioUnit", element.product.info[0].Precio);
        rootElement2.setAttribute(
          "PrecioFact",
          element.product.info[0].PrecioFact
        );
        rootElement2.setAttribute(
          "PromocionId",
          element.product.info[0].PromocionId
        );
        rootElement2.setAttribute(
          "ComponenteId",
          element.product.info[0].ComponenteId
        );
        elements[0].appendChild(rootElement2);
      });

      const xmlSerializer = new XMLSerializer();
      const xmlOutput = xmlSerializer.serializeToString(xmlDoc);
      return xmlOutput;
    },
    async BuscaInfoArticulo(value) {
      this.dialog6 = false;
      this.dialog7 = false;
      let bodyJSON = "";
      bodyJSON = JSON.stringify({
        id: value.itemno,
        client: this.clientInfo.clave.trim(),
        sucursal: this.userInfo.Sucursal.trim(),
        XML: this.cart.length > 0 ? this.createXML() : "",
      });
      /*Se deja apuntando de la version anterior confirmar con carlos si se cambia a searchInfo2*/
      const response = await fetch(this.url + "/products/product/searchInfo2", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: bodyJSON,
      });
      const decodedResponse = await response.json();
      if (response.status == 200) {
        if (decodedResponse.info.length != 0) {
          for (var arrArt of decodedResponse.info) {
            arrArt.Precio =
              arrArt.Precio - arrArt.Precio * (arrArt.Descuento / 100);
            arrArt.PrecioFact =
              arrArt.PrecioFact - arrArt.PrecioFact * (arrArt.Descuento / 100);
            arrArt.PrecioIVA =
              arrArt.PrecioIVA - arrArt.PrecioIVA * (arrArt.Descuento / 100);
          }
          let limite =
            decodedResponse.info[0].CostoProm *
            decodedResponse.info[0].Utilidad;
          if (limite < decodedResponse.info[0].Precio) {
            if (
              decodedResponse.info.length > 1 ||
              decodedResponse.info[0].PromocionId > 0
            ) {
              this.promo = decodedResponse.info;
              this.dialog4 = true;
            }
            this.product = decodedResponse;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se puede vender por debajo del costo.",
              color: "error",
            });
          }
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "No se encontro informacion",
            color: "error",
          });
        }
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    async BuscaSimilares(value) {
      console.log(value);
      this.dialog6 = false;
      let bodyJSON = "";
      bodyJSON = JSON.stringify({
        itemNo: value.itemno,
        Sucursal: this.userInfo.Sucursal.trim(),
      });
      /*Se deja apuntando de la version anterior confirmar con carlos si se cambia a searchInfo2*/
      const response = await fetch(
        this.url + "/products/articulos_equivalentes",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: bodyJSON,
        }
      );
      const decodedResponse = await response.json();
      if (response.status == 200) {
        console.log(decodedResponse);
        if (decodedResponse.length != 0) {
          this.ListArtSim = decodedResponse;
          this.dialog7 = true;
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "No se encontro informacion",
            color: "error",
          });
          this.BuscaInfoArticulo(value);
        }
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    handleRowClickArtSim(value) {
      console.log(value);
      this.dialog7 = false;
      const item = {
        itemno: value.Itemno,
        Categoria: value.Categoria,
        Existencia: value.ExistenciaSucursal,
        IdCategoria: value.IdCategoria,
        descripcion: value.Descripcion,
      };
      this.listProducts.push(item);
      this.productSelec = item;
      this.BuscaInfoArticulo(item);
    },
    async BuscarInventarioProv() {
      try {
        this.desbtnInvProv = true;
        if (this.lastItemno == this.product.info[0].Articulo) {
          this.desbtnInvProv = false;
          this.dialog2 = true;
          return;
        }
        let bodyJSON = "";
        bodyJSON = JSON.stringify({
          itemNo: this.product.info[0].Articulo,
          idUsuario: this.userInfo.IdUsuario,
        });
        const response = await fetch(
          this.url + "/ventas/TraerWSInventarioProv",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: bodyJSON,
          }
        );
        const decodedResponse = await response.json();
        if (response.status == 200) {
          console.log(decodedResponse);
          if (decodedResponse.length > 0) {
            this.lastItemno = this.product.info[0].Articulo;
            this.inventario = decodedResponse;
            this.desbtnInvProv = false;
            this.dialog2 = true;
          } else {
            this.desbtnInvProv = false;
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se tiene acceso al inventario del proveedor o el articulo no pertence a un proveedor para consultar inventario.",
              color: "error",
            });
          }
        } else {
          if (response.status == 401) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else if (response.status == 403) {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse.mensaje,
              color: "error",
            });
            this.$root.$emit("Exit");
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Ocurrio un error, intentelo mas tarde.",
              color: "error",
            });
          }
        }
      } catch (error) {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Ocurrio un error, intentelo mas tarde.",
          color: "error",
        });
      }
    },
    AbrirCatalogoElect() {
      this.dialog8 = true;
    },
    async TraerListaYears() {
      try {
        const response = await fetch(this.url + "/ventas/years", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.years = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async TraerMarcas() {
      try {
        const response = await fetch(this.url + "/ventas/marcas", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.marcas = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async TraerModelos(marca) {
      try {
        const response = await fetch(this.url + "/ventas/modelos", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            year: this.selectedYear,
            marca: marca,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.modelos = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async TraerMotores(modelo) {
      try {
        const response = await fetch(this.url + "/ventas/motores", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": this.token,
          },
          body: JSON.stringify({
            year: this.selectedYear,
            marca: this.selectedMarca,
            modelo: modelo,
          }),
        });
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.motores = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async TraerProdArt() {
      try {
        const response = await fetch(
          this.url + "/ventas/consulta_lista_prodart",
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
          }
        );
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value);
          this.prodArt = value;
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async traerProductos() {
      try {
        const array = this.selectedMotor.split(" Cil ");
        const response = await fetch(
          this.url + "/ventas/articulos_compatibles",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": this.token,
            },
            body: JSON.stringify({
              year: this.selectedYear,
              marca: this.selectedMarca,
              modelo: this.selectedModelo,
              sucursal: this.userInfo.Sucursal,
              cliente: this.clientInfo.clave,
              tipoParte: this.selectedProdArt == "" ? "" : this.selectedProdArt,
              motor: this.selectedMotor == "" ? "" : array[0],
              litros: this.selectedMotor == "" ? "" : array[1],
            }),
          }
        );
        console.log(response);
        const value = await response.json();
        if (response.status == 200) {
          console.log(value.resultado);
          if (value.resultado.length > 0) {
            this.listaProductos = value.resultado;
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "No se encontro información...",
              color: "yellow darken-3",
            });
          }
        } else {
          let body = {
            status: response.status,
            mensaje: value.mensaje,
          };
          this.$store.dispatch("responseError", body);
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleRowClickProdCat(value) {
      console.log(value);
      if (value.Existencia > 0) {
        this.searchProduct = value.CodJomar;
        this.dialog8 = false;
        // this.year = [];
        // this.selectedYear = "";
        // this.marcas = [];
        // this.selectedMarca = "";
        // this.modelos = [];
        // this.selectedModelo = "";
        // this.motores = [];
        // this.selectedMotor = "";
        // this.listaProductos = [];
        // this.selectedProdArt = "";
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "El articulo esta en 0 existencia, no se puede seleccionar...",
          color: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
#X {
  color: white;
}
#info {
  padding: 1px 18px 0 18px !important;
}
#colCard {
  padding: 8px 12px 0 12px;
}
#colCard2 {
  padding: 0 12px 0 12px;
}
#colCard3 {
  padding: 0 12px 8px 12px;
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 12px !important;
  height: 48px !important;
}
#dividerCard {
  margin-top: 14px;
  margin-bottom: 4px;
}
#sum,
#res {
  display: inline;
}
</style>
