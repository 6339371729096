<template>
  <div>
    <v-container>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-text-field
            v-model="cliente"
            label="Buscar Cliente"
            dark
            hide-details
            solo-inverted
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn block depressed color="primary" v-on:click="SearchClient()">
            Buscar Cliente
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="clientList.length != 0"
        id="rows"
        align="center"
        justify="center"
      >
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-autocomplete
            v-model="clienteSelec"
            :items="clientList"
            :item-text="getItemText"
            dense
            dark
            label="Seleccione..."
            filled
            return-object
            hide-details
            @change="(event) => InfoCliente(event)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        v-if="clienteSelec != ''"
        id="rows"
        align="center"
        justify="center"
      >
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn
            block
            depressed
            color="primary"
            v-on:click="SearchAlterClient()"
          >
            Buscar Clientes Alternos
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="clteAlterLs.length != 0"
        id="rows"
        align="center"
        justify="center"
      >
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-autocomplete
            v-model="clteAlterSelec"
            :items="clteAlterLs"
            dense
            :item-text="getItemTextAlter"
            label="Seleccione..."
            dark
            filled
            return-object
            hide-details
            @change="(event) => InfoClteAlter(event)"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row id="rows" v-if="infoClient">
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Nombre: {{ infoClient.razonsocial }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Dirección: {{ infoClient.direccion }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            RFC: {{ infoClient.rfc }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Clave: {{ infoClient.clave }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Sucursal: {{ infoClient.sucursal }}
          </div>
        </v-col>
        <v-col cols="12" v-if="mostrarCons">
          <div class="subtitle-2" style="color: white">
            Consecutivo: {{ infoClient.consecutivo }}
          </div>
        </v-col>
      </v-row>
      <v-divider dark style="margin: 8px 0 8px 0"></v-divider>
      <v-row id="rows">
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Clave vendedor: {{ userInfo.ClaveVend }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Nombre: {{ userInfo.Nombre }}
          </div>
        </v-col>
        <v-col cols="12">
          <div class="subtitle-2" style="color: white">
            Sucursal: {{ userInfo.Sucursal }}
          </div>
        </v-col>
      </v-row>
      <v-row v-if="clienteSelec != ''" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn block depressed color="primary" v-on:click="CrearPedido()">
            Crear Pedido
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ClientDetail",
  data: () => ({
    cliente: "",
    clienteSelec: false,
    clientList: [],
    clteAlterSelec: false,
    clteAlterLs: [],
    infoClient: false,
    mostrarCons: false,
  }),
  computed: {
    ...mapState(["userInfo", "url", "token"]),
    tittle: {
      get: function () {
        return this.$store.state.tittle;
      },
      set: function (val) {
        this.$store.commit("setTittle", val);
      },
    },
    clientInfo: {
      get: function () {
        return this.$store.state.clientInfo;
      },
      set: function (val) {
        this.$store.commit("setclientInfo", val);
      },
    },
  },
  mounted() {
    this.tittle = "Datos del cliente";
  },
  methods: {
    async SearchClient() {
      if (this.cliente.length < 3) {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "No se ingreso suficiente información.",
          color: "error",
        });
        return;
      }
      var obj = {
        term: this.cliente,
        sucursal: this.userInfo.Sucursal,
      };
      const response = await fetch(this.url + "/clients/search_New", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(obj),
      });
      const decodedResponse = await response.json();
      if (response.status == 200) {
        this.clientList = decodedResponse;
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    getItemText(item) {
      return `${item.clave.trim()} - ${item.razonsocial}`;
    },
    getItemTextAlter(item) {
      return `${item.clave.trim()} - ${item.consecutivo} - ${item.razonsocial}`;
    },
    async SearchAlterClient() {
      var obj = {
        ClaveCliente: this.infoClient.clave,
      };
      const response = await fetch(this.url + "/clients/searchAlternos", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": this.token,
        },
        body: JSON.stringify(obj),
      });
      const decodedResponse = await response.json();
      if (response.status == 200) {
        this.clteAlterLs = decodedResponse;
        this.clteAlterSelec = false;
      } else {
        if (response.status == 401) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else if (response.status == 403) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.mensaje,
            color: "error",
          });
          this.$root.$emit("Exit");
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Ocurrio un error, intentelo mas tarde.",
            color: "error",
          });
        }
      }
    },
    InfoCliente(value) {
      this.infoClient = value;
    },
    InfoClteAlter(value) {
      this.mostrarCons = true;
      this.infoClient = value;
    },
    CrearPedido() {
      this.clientInfo = this.infoClient;
      var info = JSON.stringify(this.infoClient);
      localStorage.setItem("infoClient", info);
      this.$router.push("/pedidocliente");
    },
  },
};
</script>
<style scoped>
#rows {
  padding: 2px 10px 0 10px;
}
</style>
