<template>
  <div>
    <v-container>
      <v-row id="topRow">
        <v-col cols="12">
          <h3 style="color: white; font-weight: 300">LOGIN</h3>
        </v-col>
      </v-row>
      <v-form ref="formLogin" v-model="valid" lazy-validation>
        <v-row id="rows" align="center" justify="center">
          <v-col cols="12" xl="6" lg="6" md="8">
            <v-text-field
              label="Usuario"
              v-model="user"
              :rules="userRules"
              required
              clearable
              hide-details
              dark
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row id="rows" align="center" justify="center">
          <v-col cols="12" xl="6" lg="6" md="8">
            <v-text-field
              label="Contraseña"
              v-model="password"
              :rules="passwordRules"
              required
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              clearable
              hide-details
              dark
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn block depressed color="primary" v-on:click="Login()">
            Iniciar Sesion
          </v-btn>
        </v-col>
      </v-row>
      <v-row id="rows" align="center" justify="center">
        <v-col cols="12" xl="6" lg="6" md="8">
          <v-btn block depressed color="primary" @click="dialog = true">
            Cambiar Contraseña
          </v-btn>
        </v-col>
      </v-row>

      <v-row id="bottomRow">
        <v-col cols="12" align="center" justify="center">
          <v-img
            max-height="160"
            max-width="160"
            src="../assets/logo_new.png"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Cambiar Contraseña</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formMod" v-model="validMod" lazy-validation>
              <v-row align="center" justify="center">
                <v-col cols="12" xl="6" lg="6" md="8">
                  <v-text-field
                    label="Usuario"
                    v-model="userMod"
                    :rules="userRules"
                    required
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" xl="6" lg="6" md="8">
                  <v-text-field
                    label="Contraseña anterior"
                    v-model="passwordlast"
                    :rules="passwordRules"
                    required
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    clearable
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" xl="6" lg="6" md="8">
                  <v-text-field
                    label="Nueva Contraseña"
                    v-model="passwordMod"
                    :rules="passwordRules"
                    required
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    clearable
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12" xl="6" lg="6" md="8">
                  <v-text-field
                    label="Nueva Contraseña"
                    v-model="confirmPass"
                    :rules="passwordRules"
                    required
                    :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show4 ? 'text' : 'password'"
                    clearable
                    @click:append="show4 = !show4"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="8">
                <v-btn
                  block
                  depressed
                  color="primary"
                  v-on:click="ChangePassword()"
                >
                  Cambiar Contraseña
                </v-btn>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-row align="center" justify="center">
              <v-col cols="12" xl="6" lg="6" md="8">
                <v-btn block depressed color="primary" @click="dialog = false">
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-footer absolute padless>
      <v-card flat tile width="100%" color="#141428" class="text-center">
        <v-card-text class="white--text">
          {{ version }} — <strong>Jomar Industrias S.A. de C.V.</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script scoped>
import { mapState } from "vuex";

export default {
  name: "LoginSession",
  data: () => ({
    user: null,
    userRules: [(v) => !!v || "Usuario es requerido"],
    password: null,
    passwordRules: [(v) => !!v || "Password es requerida"],
    show1: false,
    uuid: "",
    valid: true,
    dialog: false,
    validMod: true,
    userMod: null,
    passwordlast: null,
    passwordMod: null,
    confirmPass: null,
    show2: false,
    show3: false,
    show4: false,
  }),
  computed: {
    ...mapState(["version", "url"]),
    isAuthorized: {
      get: function () {
        return this.$store.state.isAuthorized;
      },
      set: function (val) {
        this.$store.commit("setIsAuthorized", val);
      },
    },
    userInfo: {
      get: function () {
        return this.$store.state.userInfo;
      },
      set: function (val) {
        this.$store.commit("setUserInfo", val);
      },
    },
    token: {
      get: function () {
        return this.$store.state.token;
      },
      set: function (val) {
        this.$store.commit("setToken", val);
      },
    },
    navbarToggle: {
      get: function () {
        return this.$store.state.navbarToggle;
      },
      set: function (val) {
        this.$store.commit("setNavbarToggle", val);
      },
    },
  },
  async mounted() {
    const datos = await localStorage.getItem("Login");
    if (datos != null) {
      var info = await JSON.parse(datos);
      this.user = info.usuario;
      this.password = info.password;
    }
  },
  methods: {
    async Login() {
      var validForm = this.$refs.formLogin.validate();
      // eslint-disable-next-line
      this.uuid = new DeviceUUID().get();
      if (validForm) {
        var obj = {
          email: this.user,
          password: this.password,
          version: this.version,
          uniqueId: this.uuid,
        };
        const response = await fetch(this.url + "/auth/local2New", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        });
        const decodedResponse = await response.json();
        console.log(decodedResponse);
        if (!decodedResponse.resultado[0].Resultado) {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: decodedResponse.resultado[0].Mensaje,
            color: "error",
          });
        } else {
          this.isAuthorized = true;
          this.userInfo = decodedResponse.resultado[0];
          this.token = decodedResponse.token;
          const credenciales = JSON.stringify({
            isAuthorized: true,
            userInfo: decodedResponse.resultado[0],
            token: decodedResponse.token,
          });
          const login = JSON.stringify({
            usuario: this.user,
            password: this.password,
          });
          localStorage.setItem("credenciales", credenciales);
          localStorage.setItem("Login", login);
          this.navbarToggle = 0;
          this.$root.$emit("GetLocationUser");
          this.$router.push("/clientdetail");
        }
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Favor de ingresar los campos requeridos.",
          color: "error",
        });
      }
    },
    async ChangePassword() {
      var validForm = this.$refs.formMod.validate();
      if (validForm) {
        if (this.passwordMod === this.confirmPass) {
          var obj = {
            email: this.userMod,
            oldPassword: this.passwordlast,
            newPassword: this.passwordMod,
          };
          const response = await fetch(this.url + "/auth/changePassword", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
          });
          const decodedResponse = await response.json();
          if (decodedResponse[0].Resultado == 1) {
            this.dialog = false;
            this.userMod = null;
            this.passwordMod = null;
            this.passwordlast = null;
            this.confirmPass = null;
            this.$store.commit("snackbar", {
              snackbar: true,
              text: "Contraseña actualizada con existo",
              color: "success",
            });
          } else {
            this.$store.commit("snackbar", {
              snackbar: true,
              text: decodedResponse[0].Descripcion,
              color: "error",
            });
          }
        } else {
          this.$store.commit("snackbar", {
            snackbar: true,
            text: "Error, las contraseñas no coinciden",
            color: "error",
          });
        }
      } else {
        this.$store.commit("snackbar", {
          snackbar: true,
          text: "Favor de ingresar los campos requeridos.",
          color: "error",
        });
      }
    },
  },
};
</script>

<style scoped>
#topRow {
  padding: 10px 10px 15px 10px;
}
#rows {
  padding: 2px 10px 0 10px;
}
#bottomRow {
  padding: 80px 10px 0 10px;
}
</style>
